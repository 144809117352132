<template>
    <div>
        <div class="hda">
            <div>
                <el-row>
                    <el-col :span="20">
                        <a href="" style="margin-top:25px;">
                            <img src="@/assets/img/logo4.png" alt=""
                                 style="width: 87% !important;height: 10%;padding-top: 10px;padding-left: 5%;">
                        </a>
                    </el-col>
                    <el-col :span="2" style="padding-top: 6%;">
                        <el-link icon="el-icon-search" :underline="false" @click="searchClick"></el-link>
                    </el-col>
                    <el-col :span="2" style="padding-top: 6%;">
                        <el-link icon="el-icon-s-fold" :underline="false" @click="foldClick"></el-link>
                    </el-col>
                </el-row>
            </div>
        </div>

        <div>
            <el-dialog :visible.sync="dialogVisible" width="70%" :before-close="handleClose"
                       style="padding: 5px 20px 10px;">
                <div style="display: flex;width: 92%;padding-left: 5%;">
                    <el-input v-model="input" placeholder="请输入内容" clearable></el-input>
                    <el-button type="primary" @click="searchton">搜索</el-button>
                </div>
            </el-dialog>
        </div>

        <div>
            <el-drawer :visible.sync="drawer" direction="ltr" size="100%">
                <div slot="title" class="title">
                    <img src="@/assets/img/logo4.png" alt=""
                         style="width: 84% !important;height: 10%;padding-top: 10px;padding-left: 5%;height: 12%;">
                </div>
                <div>
                    <ul class="ui">
                        <li style="list-style: none;padding-left: 10%;display: block;">
                            <p style="font-size: 20px;">
                                <a href="/move">首页</a>
                            </p>
                        </li>
                        <li style="list-style: none;padding-left: 10%;display: block;">
                            <p style="font-size: 20px;">
                                <a href="/move">机构简介</a>
                                <span @click="plusclick" class="sp">
									<i class="el-icon-plus"></i>
								</span>
                            </p>
                            <template v-if="menucode">
                                <ul :key="key" v-for="(item,key) in Profiledata"
                                    style="padding-left: 20px;line-height: 35px;">
                                    <li>
                                        <a @click="onAct(item)" style="color: #0556AD;font-family: '仿宋';">{{item.headline}}</a>
                                    </li>
                                </ul>
                            </template>
                        </li>
                        <li style="list-style: none;padding-left: 10%;">
                            <p style="font-size: 20px;">
                                <a @click="industryclick('行业资讯')">行业资讯</a>
                            </p>
                        </li>
                        <li style="list-style: none;padding-left: 10%;">
                            <p style="font-size: 20px;">
                                <a @click="industryclick('行业规范')">行业规范</a>
                            </p>
                        </li>
                        <li style="list-style: none;padding-left: 10%;">
                            <p style="font-size: 20px;">
                                <a @click="industryclick('行业观察')">行业观察</a>
                            </p>
                        </li>

                        <li style="list-style: none;padding-left: 10%;">
                            <p style="font-size: 20px;">
                                <a @click="industryclick('行业案例')">行业案例</a>
                            </p>
                        </li>

                        <li style="list-style: none;padding-left: 10%;">
                            <p style="font-size: 20px;">
                                <a @click="industryclick('活动主办')">活动主办</a>
                            </p>
                        </li>
                    </ul>
                </div>
            </el-drawer>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dialogVisible: false,
                input: "",
                drawer: false,
                innerDrawer: false,
                menucode: false,
                Profiledata: []
            };
        },
        methods: {
            searchClick() {
                this.dialogVisible = true
            },
            foldClick() {
                this.drawer = true
            },
            handleClose() {
                this.dialogVisible = false
            },
            //搜索的方法
            searchton() {
                this.$router.push({path: '/msearch', query: {searchvalue: this.input}})
                this.dialogVisible = false
            },
            plusclick() {
                this.$post("/industry/categoryall", {category: "机构简介"}).then(resp => {
                    this.Profiledata = resp.dataList
                })
                this.menucode = !this.menucode
            },
            industryclick(data) {
                this.$router.push({
                    path: "/mdetails",
                    query: {
                        headline: data
                    }
                })
                this.drawer = false
            },
            onAct(data) {
                this.$router.push({
                    path: "/mtemplate",
                    query: {
                        headlinedata: data
                    }
                })
                this.drawer = false
                /* if (data.headline === "领导介绍") {
                    this.$post("/industry/selectleader", {
                        headline: data.headline
                    }).then(resp => {
                        this.introducedatas = resp.dataList
                        this.introduceheadline = resp.dataList[0].headline
                    })
                    this.code = '1'
                    this.details = false
                } else {
                    this.$post("/industry/selectheadline",{headline: data.headline}).then(resp=>{
                        this.introducedata = resp.data
                    })
                    this.code = '0'
                    this.details = false
                } */
            },
        },
        watch: {
            $route() {
                this.input = ''
            }
        }
    }
</script>

<style>
    .hda {
        padding: 5px 0px !important;
        height: 10%;
        background: url(../../assets/img/hd-bg1.png) center;
    }

    .el-dialog__header {
        padding: 5px 20px 10px;
    }

    .el-drawer__header {
        align-items: center;
        color: #72767b;
        display: flex;
        margin-bottom: 0px;
        padding: 0px 0px 0;
        background: url(../../assets/img/hd-bg1.png) center;
        height: 14%;
    }

    .el-drawer__close-btn {
        padding-right: 3%;
        border: none;
        cursor: pointer;
        font-size: 20px;
        color: inherit;
        background-color: transparent;
    }

    .ui {
        line-height: 50px;
        min-height: 50px;
        border-bottom: #DAD9D8 1px solid;
    }

    .ui li p a {
        color: #0556AD;
        font-family: "仿宋";
        font-weight: 700;
    }

    .sp {
        display: block;
        float: right;
        width: 60px;
        height: 50px;
        background-size: 20px 20px;
        text-align: center;
        font-size: 30px;
        font-weight: bold;
    }

    .el-input--suffix .el-input__inner {
        padding-right: 0px;
    }
</style>
