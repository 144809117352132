<template>
	<div>
		<MHeader></MHeader>
		<MContent></MContent>
		<MFooter></MFooter>
	</div>
</template>

<script>
	import MFooter from '@/components/movelayout/Move_footer.vue'
	import MContent from '@/components/movelayout/Move_content.vue'
	import MHeader from '@/components/movelayout/Move_header.vue'
	export default {
		components: {
			MFooter,
			MContent,
			MHeader
		}
	}
</script>

<style>
	.el-icon-s-fold {
		content: "\e7a9";
		color: #FFF;
		font-size: 20px;
	}

	.el-icon-search {
		content: "\e778";
		color: #FFF;
		font-size: 20px;
	}

	.el-icon-close:before {
		content: "\e6db";
		color: #fff;
	}

	.el-icon-plus:before {
		content: "\e6d9";
		color: cornflowerblue;
	}
</style>
